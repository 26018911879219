<template>
  <div id="categories-list">
    <v-snackbar v-model="isSnackbarVisible" :color="snackbar.color" :timeout="snackbar.timeout" top right auto-height>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large
          >{{ snackbar.icon == 'success' ? icons.mdiCheckBold : icons.mdiCheckBold }}
        </v-icon>

        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-dialog v-model="isDialogVisible" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveData">
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Nombre"
                    dense
                    v-model="row.nombre"
                    :rules="[() => !!row.nombre || 'Este campo es requerido.']"
                    :error-messages="errorMessages"
                    ref="nombre"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    label="Código"
                    dense
                    v-model="row.codigo"
                    :rules="[() => !!row.codigo || 'Este campo es requerido.']"
                    :error-messages="errorMessages"
                    ref="codigo"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-select
                    v-if="categoriesList.length > 0"
                    v-model="row.categoria_id"
                    :items="categoriesList"
                    label="Categoría"
                    dense
                    item-text="nombre"
                    item-value="id"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field label="Descripción" dense v-model="row.descripcion"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field dense label="Foto" type="text" v-model="row.urlfoto"></v-text-field>
                </v-col>

                <v-col cols="6" sm="6">
                  <v-select
                    v-model="row.estado"
                    :items="statusList"
                    label="Estado"
                    dense
                    item-text="nombre"
                    item-value="nombre"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="isDialogVisible = false"> Cerrar </v-btn>
            <v-btn type="submit" color="success">
              <!-- @click="isDialogVisible = false" -->
              Guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- list filters -->
    <h2 class="m-2">SUBCATEGORIAS</h2>
    <v-card>
      <v-btn color="info" class="m-4" @click="showModal(1)">
        <v-icon center>{{ icons.mdiPlusCircle }} </v-icon>
        &nbsp;Subcategoría
      </v-btn>

      <!-- table -->
      <!-- v-model="selectedRows" -->
      <!-- :options.sync="options" -->
      <v-data-table :loading="loading" :headers="tableColumns" :items="items" :server-items-length="totalItems">
        <!-- name -->
        <template #[`item.urlfoto`]="{ item }">
          <div class="d-flex align-center">
            <v-img
              class="img-fluid"
              v-if="item.urlfoto"
              :src="item.urlfoto"
              width="25px"
              height="40px"
              style="margin: 8px"
            ></v-img>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}--text me-3`"
            >
              <v-icon size="18" :color="resolveUserRoleVariant(item.role)">
                {{ resolveUserRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role }}</span>
          </div>
        </template>

        <!-- plan -->
        <template #[`item.currentPlan`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.currentPlan }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.acciones`]="{ item }">
          <v-btn color="success" medium @click="showModal(2, item)">
            <v-icon center dark> {{ icons.mdiPencil }} </v-icon>
          </v-btn>
          &nbsp;
          <v-btn color="error" medium @click="deleteData(item)">
            <v-icon center dark> {{ icons.mdiDeleteOutline }} </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiCheckBold, mdiDeleteOutline, mdiPencil, mdiPlusCircle } from '@mdi/js'

import { ref } from '@vue/composition-api'

// sidebar
// import UserListAddNew from './UserListAddNew.vue'
// import userStoreModule from '../userStoreModule'

// import useUsersList from './useUsersList'

export default {
  data() {
    return {
      icons: { mdiDeleteOutline, mdiPencil, mdiPlusCircle, mdiCheckBold },
      items: [],
      table: 'subcategorias',
      tableColumns: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Foto', value: 'urlfoto', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
        { text: 'Categoría', value: 'categoria', sortable: true },
        { text: 'Estado', value: 'estado', sortable: true },
        { text: 'Acciones', value: 'acciones', sortable: false },

        // {id:0, name:}
      ],
      loading: false,
      totalItems: 0,
      isDialogVisible: false,
      row: {},
      modalTitle: 'Nueva Subcategoría',
      categoriesList: [],
      formHasErrors: false,
      errorMessages: '',
      form: ref(null),
      valid: ref(true),
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      statusList: [{ nombre: 'Activo' }, { nombre: 'Inactivo' }],
    }
  },
  async mounted() {
    await this.getData()
    await this.getCategories()
  },
  methods: {
    async saveData() {
      try {
        //pendiente la validación del form
        // this.formHasErrors = false
        let row = Object.assign({}, this.row)
        // console.log('row', row)

        // Object.keys(row).forEach(f => {
        //   if (f == 'nombre') {
        //     if (!row[f]) this.formHasErrors = true

        //     this.$refs[f].validate(true)
        //   }
        // })
        ///
        let res
        if (row.id) {
          //es update
          delete row.id
          delete row.categoria
          res = await this.$store.dispatch('put', { path: this.table + '/modify/' + this.row.id, data: row })
        } else {
          res = await this.$store.dispatch('post', { path: this.table + '/new/', data: this.row })
          //es post
        }
        this.getData()
        this.snackbar = {
          color: 'success',
          icon: 'success',
          mode: 'multi-line',
          title: 'Muy bien!',
          text: 'Registro procesado correctamente!.',
          timeout: '2500',
        }

        this.isDialogVisible = false
        this.isSnackbarVisible = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    showModal(type, item) {
      if (type == 1) {
        this.modalTitle = 'Nueva subcategoría'
        this.row = {}
      } else {
        this.row = item
        this.modalTitle = 'Editar subcategoría'
      }
      this.isDialogVisible = true
    },
    async getData() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: this.table + '/getAll/' })
        this.loading = false
        if (res.length > 0) {
          this.items = res
          this.totalItems = this.items.length
        }
      } catch (error) {
        this.items = []
        this.loading = false
        console.error('error', error)
      }
    },
    async getCategories() {
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: 'categorias/getAll/' })
        this.loading = false
        if (res.length > 0) {
          console.log(res)
          this.categoriesList = res
        }
      } catch (error) {
        this.categoriesList = []
        this.loading = false
        console.error('error', error)
      }
    },
    async deleteData(item) {
      try {
        let txt
        if (confirm('¿Eliminar Registro?')) {
          this.loading = true
          let res = await this.$store.dispatch('delete', { path: this.table + '/' + item.id })
          await this.getData()
          this.loading = false
          txt = 'You pressed OK!'
          this.snackbar = {
            color: 'success',
            icon: 'success',
            mode: 'multi-line',
            title: 'Muy bien!',
            text: 'Registro elimiado correctamente!.',
            timeout: '2500',
          }
          this.isSnackbarVisible = true
        } else {
          //txt = 'You pressed Cancel!'
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
